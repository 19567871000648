import { TabPanel } from 'designsystem';
import { FC } from 'react';
import {
    AlphabetSearchFiltersQuery,
    AlphabetSearchFiltersQueryVariables,
    AlphabetSearchHitsQuery,
    AlphabetSearchHitsQueryVariables,
    FestivalSectionsQuery,
    FestivalSectionsQueryVariables,
    ScheduleFilterEnum,
    SearchScheduleFiltersQuery,
    SearchScheduleFiltersQueryVariables,
    SearchScheduleHitsQuery,
    SearchScheduleHitsQueryVariables,
    useAlphabetSearchFiltersQuery,
    useAlphabetSearchHitsQuery,
    useFestivalSectionsQuery,
    useSearchScheduleFiltersQuery,
    useSearchScheduleHitsQuery,
} from '../../gql/api';

import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { fetchApiData, fetchCraftData, ProgrammePageTemplate, useRemoveQueryFilters } from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import { ContentPage, DEFAULT_REVALIDATION_TIME } from '../../pages/[...uri]';
import FestivalBreadcrumbs from '../FestivalBreadcrumbs';
import ProgrammeScheduleSearch from '../programme/ProgrammeScheduleSearch';
import ProgrammeAlphabetSearch from '../programme/ProgrammeAlphabetSearch';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useRouter } from 'next/router';
import ProgrammeFestivalSections from '../programme/ProgrammeFestivalSections';
import {
    AlphabetSearchFilterQueryParams,
    getClosestFutureDay,
    ScheduleSearchFilterQueryParams,
} from '../../utils/searchProgrammeUtils';
import { ITEMS_PER_PAGE } from '../../hooks/useAlphabetSearch';
import { NextSeo, NextSeoProps } from 'next-seo';
import { PathwaysQuery, PathwaysQueryVariables, usePathwaysQuery } from '../../gql/cms';
import ProgrammePathways from '../programme/ProgrammePathways';
import { useIntl } from 'react-intl';

const defaultTab = process.env.NEXT_PUBLIC_PROGRAMME_DEFAULT_TAB
    ? Number(process.env.NEXT_PUBLIC_PROGRAMME_DEFAULT_TAB)
    : 0;

const defaultMetadata: Record<string, NextSeoProps> = {
    nl: {
        description:
            'Hier vind je het complete IDFA 2023 festivalprogramma van films, nieuwemediaprojecten en evenementen voor professionals. Zoek op titel of gebruik een filter om films te vinden die aansluiten bij je interesses. ',
    },
    en: {
        description:
            'Here you can find the complete IDFA 2023 program of films, new media projects and professional events. Find a title by using the filter to quickly find films that suit your interests.',
    },
};

type ProgrammeOverviewPageData = Extract<ContentPage, { __typename: 'festivalContentPages_programPage_Entry' }>;

const ProgrammeOverviewPage: FC<{ data: ProgrammeOverviewPageData }> = ({ data }) => {
    const { query, locale } = useRouter();
    const [tabIndex, setTabIndex] = useQueryParam(
        'tabIndex',
        withDefault(NumberParam, query.tabIndex ? Number(query.tabIndex) : defaultTab)
    );
    const removeQueryFilters = useRemoveQueryFilters({
        ...AlphabetSearchFilterQueryParams,
        ...ScheduleSearchFilterQueryParams,
    });
    const [, setPage] = useQueryParam('page', NumberParam);
    const { formatMessage } = useIntl();

    return (
        <>
            <NextSeo title={data?.title} {...defaultMetadata[locale]} />
            <ProgrammePageTemplate
                introText={data?.introText}
                onChange={index => {
                    removeQueryFilters();
                    setTabIndex(index);
                    setPage(1);
                }}
                pageTitle={data?.title}
                topChildren={<FestivalBreadcrumbs />}
                tabIndex={tabIndex}
                tabs={[
                    formatMessage({
                        defaultMessage: 'Per dag',
                        id: 'programme.tabs.schedule',
                    }),
                    'A-Z',
                    formatMessage({
                        defaultMessage: 'Secties',
                    }),
                    formatMessage({
                        defaultMessage: 'Pathways',
                    }),
                ]}
                tabPanels={[
                    <TabPanel key={0} py={[6, null, null, 9]} px={0}>
                        <ProgrammeScheduleSearch />
                    </TabPanel>,
                    <TabPanel key={1} py={[6, null, null, 9]} px={0}>
                        <ProgrammeAlphabetSearch />
                    </TabPanel>,
                    <TabPanel key={2} py={[6, null, null, 9]} px={0}>
                        <ProgrammeFestivalSections />
                    </TabPanel>,
                    <TabPanel key={2} py={[6, null, null, 9]} px={0}>
                        <ProgrammePathways />
                    </TabPanel>,
                ]}
            />
        </>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const searchScheduleFiltersVars: SearchScheduleFiltersQueryVariables = {
        filters: [],
    };

    const { searchSchedule } = await queryClient.fetchQuery(
        useSearchScheduleFiltersQuery.getKey(searchScheduleFiltersVars),
        () =>
            fetchApiData<SearchScheduleFiltersQuery, SearchScheduleFiltersQueryVariables>({
                query: useSearchScheduleFiltersQuery.document,
                variables: searchScheduleFiltersVars,
                locale,
            })
    );

    const initialFilters = searchSchedule?.filters;
    if (initialFilters) {
        let searchScheduleHitsVars: SearchScheduleHitsQueryVariables = {
            filters: [{ key: ScheduleFilterEnum.Day, value: [getClosestFutureDay(initialFilters)] }],
        };
        await queryClient.prefetchQuery(useSearchScheduleHitsQuery.getKey(searchScheduleHitsVars), () =>
            fetchApiData<SearchScheduleHitsQuery, SearchScheduleHitsQueryVariables>({
                query: useSearchScheduleHitsQuery.document,
                variables: searchScheduleHitsVars,
                locale,
            })
        );

        // We do a client side redirect to the public shows filter, so as a workaround we need to dehydrate that query as well...
        searchScheduleHitsVars = {
            filters: [
                { key: ScheduleFilterEnum.Day, value: [getClosestFutureDay(initialFilters)] },
                { key: ScheduleFilterEnum.ShowType, value: [locale === 'nl' ? 'Publiek' : 'Public'] },
            ],
        };
        await queryClient.prefetchQuery(useSearchScheduleHitsQuery.getKey(searchScheduleHitsVars), () =>
            fetchApiData<SearchScheduleHitsQuery, SearchScheduleHitsQueryVariables>({
                query: useSearchScheduleHitsQuery.document,
                variables: searchScheduleHitsVars,
                locale,
            })
        );
    }

    const alphabetSearchFilterVars: AlphabetSearchFiltersQueryVariables = {
        filters: [],
    };
    await queryClient.prefetchQuery(useAlphabetSearchFiltersQuery.getKey(alphabetSearchFilterVars), () =>
        fetchApiData<AlphabetSearchFiltersQuery, AlphabetSearchFiltersQueryVariables>({
            query: useAlphabetSearchFiltersQuery.document,
            variables: alphabetSearchFilterVars,
            locale,
        })
    );

    const alphabetSearchHitsVars: AlphabetSearchHitsQueryVariables = {
        query: '',
        limit: ITEMS_PER_PAGE,
        offset: 0,
        filters: [],
    };

    await queryClient.prefetchQuery(useAlphabetSearchHitsQuery.getKey(alphabetSearchHitsVars), () =>
        fetchApiData<AlphabetSearchHitsQuery, AlphabetSearchHitsQueryVariables>({
            query: useAlphabetSearchHitsQuery.document,
            variables: alphabetSearchHitsVars,
            locale,
        })
    );

    await queryClient.prefetchQuery(useFestivalSectionsQuery.getKey(), () =>
        fetchApiData<FestivalSectionsQuery, FestivalSectionsQueryVariables>({
            query: useFestivalSectionsQuery.document,
            locale,
        })
    );

    await queryClient.prefetchQuery(usePathwaysQuery.getKey(), () =>
        fetchCraftData<PathwaysQuery, PathwaysQueryVariables>({
            query: usePathwaysQuery.document,
        })
    );

    return {
        props: {
            intlMessages: await loadIntlMessages(locale),
            dehydratedState: dehydrate(queryClient),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export default ProgrammeOverviewPage;
