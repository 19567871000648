import { NumberParam, StringParam, useQueryParam, useQueryParams, withDefault } from 'use-query-params';
import { FestivalFilmsFilterEnum, useAlphabetSearchFiltersQuery, useAlphabetSearchHitsQuery } from '../gql/api';
import { useDebounce } from 'use-debounce';
import {
    AlphabetSearchFilterQueryParams,
    dropdownFilterNames,
    getFilterVariables,
    hitsByFirstLetterMap,
} from '../utils/searchProgrammeUtils';
import { useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { gtm, useRemoveQueryFilters } from 'shared';

export const ITEMS_PER_PAGE = 30;
const PageParam = withDefault(NumberParam, 1);

const parseFilterTitle = (key: string) => (key.includes('|') ? key.split('|')[1] : key);

const useAlphabetSearch = () => {
    const { data: alphabetSearchFilters, refetch } = useAlphabetSearchFiltersQuery({ filters: [] });
    const [queryParams, setQueryParams] = useQueryParams({ ...AlphabetSearchFilterQueryParams });
    const [searchQuery, setSearchQuery] = useQueryParam('searchQuery', withDefault(StringParam, ''));
    const [page, setPage] = useQueryParam('page', PageParam);
    const [debouncedQuery] = useDebounce(searchQuery, 400);
    const queryVariables = useMemo(() => getFilterVariables<FestivalFilmsFilterEnum>(queryParams), [queryParams]);
    const { locale } = useRouter();
    const prevLocale = useRef(locale);
    const removeQueryFilters = useRemoveQueryFilters(AlphabetSearchFilterQueryParams);

    useEffect(() => {
        if (prevLocale.current !== locale && refetch) {
            // refetch filters on lang switch
            refetch();
            prevLocale.current = locale;
            removeQueryFilters();
        }
    }, [locale, prevLocale, refetch, removeQueryFilters]);

    const dropdownFilters = useMemo(
        () =>
            alphabetSearchFilters?.searchFestivalFilms.filters
                .filter(filterObj => dropdownFilterNames.some(dropdownFilter => filterObj.filter === dropdownFilter))
                .map(filterObj => ({
                    ...filterObj,
                    amounts: filterObj.amounts.sort((a, b) =>
                        parseFilterTitle(a.key).localeCompare(parseFilterTitle(b.key))
                    ),
                })),
        [alphabetSearchFilters?.searchFestivalFilms.filters]
    );

    const activeFilters = useMemo(
        () => queryVariables.filter(filterVariable => filterVariable.value?.some(val => !!val)),
        [queryVariables]
    );

    const { data: alphabetSearchHits, isLoading } = useAlphabetSearchHitsQuery({
        query: debouncedQuery,
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
        filters: activeFilters,
    });

    const hitsByFirstLetter = useMemo(
        () => hitsByFirstLetterMap(alphabetSearchHits?.searchFestivalFilms?.hits),
        [alphabetSearchHits?.searchFestivalFilms?.hits]
    );

    useEffect(() => {
        if (debouncedQuery !== '') {
            gtm.event('search_collection', {
                search_term: debouncedQuery,
                collection: 'festival',
            });
        }
    }, [debouncedQuery]);

    return {
        hits: hitsByFirstLetter,
        hitsLoading: isLoading,
        totalHits: alphabetSearchHits?.searchFestivalFilms?.totalHits,
        allFilters: alphabetSearchFilters?.searchFestivalFilms.filters,
        activeFilters,
        queryParams,
        queryVariables,
        dropdownFilters,
        searchQuery,
        currentPage: page,
        itemsPerPage: ITEMS_PER_PAGE,
        setQueryParams,
        setSearchQuery,
        setPage,
        removeQueryFilters,
    };
};

export default useAlphabetSearch;
