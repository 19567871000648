import { FC } from 'react';
import useAlphabetSearch from '../../hooks/useAlphabetSearch';
import { Flex, Heading, Meta, Note, Pagination, ProgrammeListItem } from 'designsystem';
import ProgrammeListSection from './ProgrammeListSection';
import {
    getMetaForFilmOrProjectHit,
    isLoggedIn,
    slugifyTitle,
    stripHtml,
    useGetApiImageProps,
    useSignIn,
} from 'shared';
import ProgrammeAlphabetFilters from './ProgrammeAlphabetFilters';
import ProgrammeListSkeleton from './ProgrammeListSkeleton';
import { FormattedMessage } from 'react-intl';
import useToggleFilmFavorite from '../../hooks/useToggleFilmFavorite';
import { useSession } from 'next-auth/react';

const ProgrammeAlphabetSearch: FC = () => {
    const { hits, hitsLoading, currentPage, totalHits, itemsPerPage, setPage } = useAlphabetSearch();
    const getImgProps = useGetApiImageProps();
    const { toggleFavorite, isFavorited, isLoadingFavorites } = useToggleFilmFavorite();
    const { status } = useSession();
    const { signIn } = useSignIn();

    return (
        <>
            <ProgrammeAlphabetFilters />
            {!hitsLoading &&
                hits &&
                Object.keys(hits)
                    .sort()
                    .map(firstLetter => (
                        <ProgrammeListSection key={firstLetter}>
                            <Heading variant={2}>{firstLetter.toUpperCase()}</Heading>
                            {hits[firstLetter]?.length > 0 &&
                                hits[firstLetter].map(hit => (
                                    <ProgrammeListItem
                                        key={hit.id}
                                        name={hit.fullPreferredTitle}
                                        meta={<Meta size="s" {...getMetaForFilmOrProjectHit({ filmOrProject: hit })} />}
                                        description={stripHtml(hit.intro?.translation ?? hit.description?.translation)}
                                        image={getImgProps(hit.publications?.favoriteImage, hit.fullPreferredTitle)}
                                        tags={
                                            hit.sections &&
                                            hit.sections.map(section => ({
                                                key: section.id,
                                                value: section.name,
                                            }))
                                        }
                                        showCalendarIcon={false}
                                        toggleFavorite={() => (isLoggedIn(status) ? toggleFavorite(hit.id) : signIn())}
                                        isFavorited={isFavorited(hit.id)}
                                        isLoadingFavorite={isLoadingFavorites}
                                        href={`/film/${hit.id}/${slugifyTitle(hit.fullPreferredTitle)}`}
                                    />
                                ))}
                        </ProgrammeListSection>
                    ))}
            {!hitsLoading && Object.keys(hits ?? {}).length === 0 && (
                <Note mt={[6, null, null, 13]}>
                    <FormattedMessage defaultMessage="Geen films gevonden voor deze zoekopdracht." />
                </Note>
            )}
            {!hitsLoading && totalHits > itemsPerPage && (
                <Flex justifyContent="center" mt={[6, null, 9]}>
                    <Pagination
                        currentPage={currentPage - 1}
                        setCurrentPage={(p: number) => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            });
                            setPage(p + 1);
                        }}
                        totalPages={Math.ceil(totalHits / itemsPerPage)}
                    />
                </Flex>
            )}
            {hitsLoading && <ProgrammeListSkeleton />}
        </>
    );
};

export default ProgrammeAlphabetSearch;
