import { useQueryClient } from '@tanstack/react-query';
import { useAddFilmToFavoritesMutation, useFavoriteFilmsQuery, useRemoveFilmFromFavoritesMutation } from '../gql/api';
import { useIsSigningIn, useProfileQuery } from 'shared';
import { useCallback } from 'react';
import { useSession } from 'next-auth/react';

const useToggleFilmFavorite = () => {
    const queryClient = useQueryClient();
    const { status } = useSession();
    const isProfileEnabled = status === 'authenticated';
    const { data: userData, isLoading: isLoadingProfile } = useProfileQuery(undefined, {
        enabled: isProfileEnabled,
    });

    const { mutate: addToFavorites, isLoading: isAdding } = useAddFilmToFavoritesMutation({
        onSuccess(response) {
            const updatedUserData = {
                ...userData,
                viewer: {
                    ...userData?.viewer,
                    ...response.addFilmToFavorites.user,
                },
            };
            queryClient.setQueryData(useProfileQuery.getKey(), updatedUserData);
            queryClient.setQueryData(useFavoriteFilmsQuery.getKey(), updatedUserData);
        },
    });

    const { mutate: removeFromFavorites, isLoading: isRemoving } = useRemoveFilmFromFavoritesMutation({
        onSuccess(response) {
            const updatedUserData = {
                ...userData,
                viewer: {
                    ...userData?.viewer,
                    ...response.removeFilmFromFavorites.user,
                },
            };
            queryClient.setQueryData(useProfileQuery.getKey(), updatedUserData);
            queryClient.setQueryData(useFavoriteFilmsQuery.getKey(), updatedUserData);
        },
    });
    const isSigningIn = useIsSigningIn();
    const isLoading = isAdding || isRemoving || isSigningIn || (isProfileEnabled ? isLoadingProfile : false);

    const toggleFavorite = useCallback(
        (filmId: string) =>
            userData?.viewer?.favoriteFilms?.some(userFavorite => userFavorite.id === filmId)
                ? removeFromFavorites({ input: { filmId } })
                : addToFavorites({ input: { filmId } }),
        [userData?.viewer?.favoriteFilms, removeFromFavorites, addToFavorites]
    );

    const isFavorited = useCallback(
        (filmId: string) => userData?.viewer?.favoriteFilms?.some(userFavorite => userFavorite.id === filmId),
        [userData?.viewer?.favoriteFilms]
    );

    return {
        toggleFavorite,
        isFavorited,
        isLoadingFavorites: isLoading,
    };
};

export default useToggleFilmFavorite;
