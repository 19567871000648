import { Body, FestivalProgrammeHero, Heading, HtmlContent, Wrapper } from 'designsystem';
import { FC } from 'react';
import {
    AlphabetSearchFiltersQuery,
    AlphabetSearchFiltersQueryVariables,
    AlphabetSearchHitsQuery,
    AlphabetSearchHitsQueryVariables,
    useAlphabetSearchFiltersQuery,
    useAlphabetSearchHitsQuery,
} from '../../gql/api';

import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { fetchApiData } from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import { ContentPage, DEFAULT_REVALIDATION_TIME } from '../../pages/[...uri]';
import FestivalBreadcrumbs from '../FestivalBreadcrumbs';
import ProgrammeAlphabetSearch from '../programme/ProgrammeAlphabetSearch';
import { ITEMS_PER_PAGE } from '../../hooks/useAlphabetSearch';
import { NextSeo } from 'next-seo';
import striptags from 'striptags';

type ProgramCollectionPageData = Extract<
    ContentPage,
    { __typename: 'festivalContentPages_festivalCollectionPage_Entry' }
>;

const ProgramCollectionPage: FC<{ data: ProgramCollectionPageData }> = ({ data }) => (
    <>
        <NextSeo title={data?.title} description={striptags(data?.introText)} />
        <FestivalProgrammeHero topChildren={<FestivalBreadcrumbs />}>
            <Heading mt={['72px', null, '112px']} clamp={1} variant={1}>
                {data?.title}
            </Heading>
            <Body mt={[4, null, 7]} pb={13}>
                <HtmlContent html={data?.introText} />
            </Body>
        </FestivalProgrammeHero>
        <Wrapper py={[6, null, null, 9]}>
            <ProgrammeAlphabetSearch />
        </Wrapper>
    </>
);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();

    const alphabetSearchFilterVars: AlphabetSearchFiltersQueryVariables = {
        filters: [],
    };
    await queryClient.prefetchQuery(useAlphabetSearchFiltersQuery.getKey(alphabetSearchFilterVars), () =>
        fetchApiData<AlphabetSearchFiltersQuery, AlphabetSearchFiltersQueryVariables>({
            query: useAlphabetSearchFiltersQuery.document,
            variables: alphabetSearchFilterVars,
            locale,
        })
    );

    const alphabetSearchHitsVars: AlphabetSearchHitsQueryVariables = {
        query: '',
        limit: ITEMS_PER_PAGE,
        offset: 0,
        filters: [],
    };
    await queryClient.prefetchQuery(useAlphabetSearchHitsQuery.getKey(alphabetSearchHitsVars), () =>
        fetchApiData<AlphabetSearchHitsQuery, AlphabetSearchHitsQueryVariables>({
            query: useAlphabetSearchHitsQuery.document,
            variables: alphabetSearchHitsVars,
            locale,
        })
    );

    return {
        props: {
            intlMessages: await loadIntlMessages(locale),
            dehydratedState: dehydrate(queryClient),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export default ProgramCollectionPage;
