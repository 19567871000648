import { Body, Button, Checkbox, Dropdown, FilterTag, Flex, HStack, SearchInput, useDisclosure } from 'designsystem';
import { ChangeEvent, FC, Ref, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Filter, FilterModal, FiltersDrawer, gtm, useSetQueryFilter } from 'shared';
import alphabetFilterMessages from '../../utils/alphabetFilterMessages';
import { AlphabetSearchFilterQueryParams } from '../../utils/searchProgrammeUtils';
import useAlphabetSearch from '../../hooks/useAlphabetSearch';
import { StyledIconFilters } from './ProgrammeScheduleFilters';
import { FestivalFilmsFilterEnum } from '../../gql/api';

const ProgrammeAlphabetFilters: FC = () => {
    const { formatMessage } = useIntl();
    const {
        searchQuery,
        dropdownFilters,
        allFilters,
        activeFilters,
        totalHits,
        currentPage,
        setSearchQuery,
        setQueryParams,
        setPage,
        removeQueryFilters,
    } = useAlphabetSearch();
    const drawerProps = useDisclosure();
    const modalProps = useDisclosure();
    const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);
    const onModal = useCallback(
        (filter: Filter) => {
            setSelectedFilter(filter);
            modalProps.onOpen();
        },
        [modalProps]
    );
    const inputRef: Ref<HTMLInputElement> = useRef();
    const setQueryFilter = useSetQueryFilter(AlphabetSearchFilterQueryParams);

    const clearFilter = (key: FestivalFilmsFilterEnum, value: string[], deleteValue: string) => {
        setQueryParams({
            [key]: value.filter(val => val !== deleteValue),
        });
    };

    return (
        <>
            <SearchInput
                defaultValue={searchQuery}
                ref={inputRef}
                py={3}
                px={7}
                borderRadius="100px"
                border="1.5px solid"
                placeholder={formatMessage({ defaultMessage: 'Zoek op filmtitel' })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const searchValue = e.target.value;
                    gtm.event('search', {
                        search_term: searchValue,
                    });
                    inputRef.current.value = searchValue;
                    setSearchQuery(searchValue);
                }}
                onClear={() => {
                    inputRef.current.value = '';
                    setSearchQuery('');
                }}
            />
            <Flex mt={[4, null, null, 6]}>
                <HStack display={['none', null, null, 'flex']} spacing={6}>
                    {dropdownFilters?.map(
                        dropdownFilter =>
                            dropdownFilter.amounts.length > 0 && (
                                <Dropdown
                                    key={dropdownFilter.filter}
                                    title={formatMessage(alphabetFilterMessages[dropdownFilter.filter])}
                                >
                                    {dropdownFilter.amounts.map(amountObj => (
                                        <Flex
                                            key={amountObj.key}
                                            gap={6}
                                            as="label"
                                            htmlFor={amountObj.key}
                                            flex={1}
                                            alignItems="center"
                                        >
                                            <Body flex={1}>
                                                {amountObj.key.includes('|')
                                                    ? amountObj.key.split('|')[1]
                                                    : amountObj.key}
                                            </Body>
                                            <Body color="grey.60">({amountObj.amount})</Body>
                                            <Checkbox
                                                id={amountObj.key}
                                                isChecked={activeFilters.some(
                                                    filter =>
                                                        filter.key === dropdownFilter.filter &&
                                                        filter.value.includes(amountObj.key)
                                                )}
                                                onChange={e => {
                                                    setQueryFilter(
                                                        dropdownFilter.filter,
                                                        amountObj.key,
                                                        e.currentTarget.checked
                                                    );
                                                    if (currentPage > 1) {
                                                        setPage(1);
                                                    }
                                                }}
                                            />
                                        </Flex>
                                    ))}
                                </Dropdown>
                            )
                    )}
                </HStack>

                <Button
                    ml="auto"
                    flexShrink={['unset', null, null, 1]}
                    w={['100%', null, null, 'auto']}
                    rightIcon={<StyledIconFilters />}
                    onClick={() => drawerProps.onOpen()}
                >
                    Filters
                </Button>
            </Flex>
            {activeFilters.length > 0 && (
                <HStack mt={6} flexWrap="wrap" spacing={2}>
                    {activeFilters.map(({ key, value }) => (
                        <>
                            {value.map(val => (
                                <FilterTag key={val} onClear={() => clearFilter(key, value, val)}>
                                    {val.includes('|') ? val.split('|')[1] : val}
                                </FilterTag>
                            ))}
                        </>
                    ))}
                    {activeFilters.length > 0 && (
                        <FilterTag onClear={() => removeQueryFilters()}>
                            <FormattedMessage defaultMessage="Alles wissen" />
                        </FilterTag>
                    )}
                </HStack>
            )}
            {allFilters?.length > 0 && (
                <FiltersDrawer
                    {...drawerProps}
                    queryParams={AlphabetSearchFilterQueryParams}
                    filters={allFilters}
                    onModal={onModal}
                    collectionFilterMessages={alphabetFilterMessages}
                    totalHits={totalHits}
                    closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                    modal={
                        <FilterModal
                            {...modalProps}
                            filter={selectedFilter}
                            filterMessages={alphabetFilterMessages}
                            queryParams={AlphabetSearchFilterQueryParams}
                            closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                        />
                    }
                />
            )}
        </>
    );
};

export default ProgrammeAlphabetFilters;
