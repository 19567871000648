import { FestivalFilmsFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const alphabetFilterMessages = defineMessages<FestivalFilmsFilterEnum>({
    [FestivalFilmsFilterEnum.CountriesOfProduction]: { defaultMessage: 'Productieland' },
    [FestivalFilmsFilterEnum.IdfaAwards]: { defaultMessage: 'IDFA Award' },
    [FestivalFilmsFilterEnum.LengthInMinutes]: { defaultMessage: 'Lengte' },
    [FestivalFilmsFilterEnum.Pathway]: { defaultMessage: 'Wegwijzer' },
    [FestivalFilmsFilterEnum.Premiere]: { defaultMessage: 'Premiere' },
    [FestivalFilmsFilterEnum.Section]: { defaultMessage: 'Sectie' },
    [FestivalFilmsFilterEnum.Tags]: { defaultMessage: 'Onderwerp' },
});

export default alphabetFilterMessages;
