import { ReactNode } from 'react';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import {
    AlphabetSearchHitsQuery,
    FestivalFilmsFilterEnum,
    GuestbookFilterEnum,
    ScheduleFilterEnum,
    SearchGuestlistHitsQuery,
    SearchScheduleFiltersQuery,
    SearchScheduleHitsQuery,
} from '../gql/api';
import { differenceInDays, format, isToday, parse, startOfToday } from 'date-fns';
import groupBy from 'lodash.groupby';

type FilterSelectField = {
    key: string;
    label: ReactNode;
    select: ReactNode;
};

type ScheduleFilterQueryParams = typeof ScheduleSearchFilterQueryParams;

type SearchScheduleHits = SearchScheduleHitsQuery['searchSchedule']['hits'];

const ScheduleSearchFilterQueryParams: Record<ScheduleFilterEnum, QueryParamConfig<string[], string[]>> = Object.assign(
    {},
    ...Object.values(ScheduleFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const selectFilterFields = ['DAY', 'TIME', 'VENUE'];

const hitsByHourMap = (hits: SearchScheduleHits) =>
    hits ? groupBy(hits, hit => new Date(hit.startOn).getHours()) : undefined;

const getHitsFromTime = (hitsByHour: ReturnType<typeof hitsByHourMap>, selectedTime: number | null) => {
    if (hitsByHour && selectedTime) {
        const hoursMapKeys = Object.keys(hitsByHour);
        const selectedTimeKeyIndex = hoursMapKeys.indexOf(selectedTime.toString());
        const hoursMap = { ...hitsByHour };

        for (let i = 0; i < selectedTimeKeyIndex; i += 1) {
            delete hoursMap[hoursMapKeys[i]];
        }
        return hoursMap;
    }
    return undefined;
};

const sortByDateDesc = (a: { key: string }, b: { key: string }) => Number(new Date(a.key)) - Number(new Date(b.key));

const sortDesc = (a: { key: string }, b: { key: string }) => {
    if (a.key < b.key) {
        return -1;
    }
    if (a.key > b.key) {
        return 1;
    }
    return 0;
};

const getFilterVariables = <FilterEnum extends ScheduleFilterEnum | FestivalFilmsFilterEnum | GuestbookFilterEnum>(
    queryFilters: Record<FilterEnum | string, string[]>
): { key: FilterEnum; value: string[] }[] =>
    Object.keys(queryFilters).map(queryFilterKey => ({
        key: queryFilterKey as FilterEnum,
        value: queryFilters[queryFilterKey],
    }));

const getFirstDay = (filters: SearchScheduleFiltersQuery['searchSchedule']['filters']) => {
    const dayFilters = filters.find(filterObj => filterObj.filter === 'DAY');
    if ((dayFilters?.amounts?.length ?? 0) === 0) {
        return null;
    }
    const todayInSchedule = dayFilters.amounts.some(dayFilter => isToday(new Date(dayFilter.key)));
    if (todayInSchedule) {
        return format(startOfToday(), 'yyyy-MM-dd');
    }
    return dayFilters.amounts[0].key;
};

const getClosestFutureDay = (filters: SearchScheduleFiltersQuery['searchSchedule']['filters']) => {
    const dayFilters = filters.find(filterObj => filterObj.filter === 'DAY');

    if ((dayFilters?.amounts?.length ?? 0) === 0) {
        return null;
    }

    const today = startOfToday();
    const closestFutureDate = dayFilters.amounts
        .map(dayFilter => parse(dayFilter.key, 'yyyy-MM-dd', new Date()))
        .filter(date => differenceInDays(date, today) >= 0)
        .sort((a, b) => differenceInDays(a, b))[0];

    if (closestFutureDate) {
        return format(closestFutureDate, 'yyyy-MM-dd');
    }

    return dayFilters.amounts[0].key;
};

type AlphabetSearchHits = AlphabetSearchHitsQuery['searchFestivalFilms']['hits'];
type SearchGuestlistHits = SearchGuestlistHitsQuery['searchGuestbook']['hits'];

const AlphabetSearchFilterQueryParams: Record<
    FestivalFilmsFilterEnum,
    QueryParamConfig<string[], string[]>
> = Object.assign(
    {},
    ...Object.values(FestivalFilmsFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const getFirstLetter = (title: string | null) => {
    const firstChar = title?.replace(/[^a-zA-Z1-9]/g, '').trim()[0];
    if (Number.isInteger(Number(firstChar))) {
        // first character is a number (grouped under symbol '#')
        return '#';
    }
    return firstChar?.toLowerCase();
};

const hitsByFirstLetterMap = (hits: AlphabetSearchHits) =>
    hits ? groupBy(hits, hit => getFirstLetter(hit?.sortedTitle)) : undefined;

const dropdownFilterNames: FestivalFilmsFilterEnum[] = [
    FestivalFilmsFilterEnum.Section,
    FestivalFilmsFilterEnum.Pathway,
    FestivalFilmsFilterEnum.LengthInMinutes,
    FestivalFilmsFilterEnum.Tags,
];

const SearchGuestlistFilterQueryParams: Record<
    GuestbookFilterEnum,
    QueryParamConfig<string[], string[]>
> = Object.assign({}, ...Object.values(GuestbookFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) })));

export {
    getFirstDay,
    getClosestFutureDay,
    getFilterVariables,
    getFirstLetter,
    getHitsFromTime,
    hitsByHourMap,
    hitsByFirstLetterMap,
    sortByDateDesc,
    sortDesc,
    selectFilterFields,
    dropdownFilterNames,
    ScheduleSearchFilterQueryParams,
    AlphabetSearchFilterQueryParams,
    SearchGuestlistFilterQueryParams,
};
export type {
    FilterSelectField,
    ScheduleFilterQueryParams,
    SearchScheduleHits,
    AlphabetSearchHits,
    SearchGuestlistHits,
};
