import { MessageDescriptor, defineMessages } from 'react-intl';

export type ShowTypeMessageKey = 'public' | 'publiek' | 'industry' | 'nieuweMedia' | 'newMedia' | 'online';

const showTypeFilterMessages = defineMessages<ShowTypeMessageKey, MessageDescriptor>({
    public: {
        defaultMessage: 'Screenings & Events',
    },
    publiek: {
        defaultMessage: 'Screenings & Events',
    },
    industry: {
        defaultMessage: 'Industry',
    },
    // at time of writing these SHOW_TYPE filters below don't exist in test data
    nieuweMedia: {
        defaultMessage: 'Nieuwe Media',
    },
    newMedia: {
        defaultMessage: 'New Media',
    },
    online: {
        defaultMessage: 'Online',
    },
});

export default showTypeFilterMessages;
